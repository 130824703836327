import type { GQLBusinessIdeaType } from "graphql/generated-types";
import DarkCard from "containers/DarkCard";
import ProgressChart from "./ProgressChart";
import ChevronLink from "./ChevronLink";
import ProgressBar from "./ProgressBar";
import CountOfQuestionsAnswered from "./CountOfQuestionsAnswered";
import Link from "next/link";

interface CardProps {
	idea: GQLBusinessIdeaType;
}

const IdeaCard = ({ idea }: CardProps) => {
	const totalAnswered = idea.profiles!.reduce((accOuter, profile) => {
		const total = profile.quizProgress!.reduce((accInner, progress) => accInner + progress.answeredCount!, 0);
		return accOuter + total;
	}, 0);

	const totalQuestions = idea.profiles!.reduce((accOuter, profile) => {
		const total = profile.quizProgress!.reduce((accInner, progress) => accInner + progress.questionCount!, 0);
		return accOuter + total;
	}, 0);

	const percentage = Math.round((totalAnswered / totalQuestions) * 100);
	return (
		<Link href={`idea/${idea.id}`} className="hover:cursor-pointer">
			<DarkCard className="flex flex-col justify-between pr-10">
				<h2 className="text-20px lg:text-28px">{idea.title}</h2>
				<ProgressBar percentage={percentage} />
				<CountOfQuestionsAnswered answeredCount={totalAnswered} questionCount={totalQuestions} />
				<ChevronLink text="View idea profile" />
				<ProgressChart progress={idea.rating!} total={100} description={["Commercial", "Feasibility Rating"]} />
			</DarkCard>
		</Link>
	);
};

export default IdeaCard;
