"use client";

import IdeaCard from "components/IdeaCard";
import {
	GQLDashboardPage,
	GQLAllIdeasQuery,
	GQLCreateNewIdeaMutationVariables,
	CreateNewIdeaDocument,
	GQLBusinessIdeaType,
} from "graphql/generated-types";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Dialog } from "@headlessui/react";
import { useRouter } from "next/navigation";
import ButtonPanel from "components/ButtonPanel";
import DarkCard from "containers/DarkCard";
import Input from "components/formFields/Input";
import { MobileHorizontalRule } from "components/HorizontalRule";
import { clientRequest } from "utils/request";

type DashboardProps = {
	page: GQLDashboardPage;
	ideas: GQLAllIdeasQuery["ideas"] | null;
};

const DashboardPage: React.FC<DashboardProps> = ({ page, ideas }) => {
	let [isOpen, setIsOpen] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(false);
	let { handleSubmit, control } = useForm<GQLCreateNewIdeaMutationVariables>();
	let router = useRouter();

	const handle = handleSubmit(async (data) => {
		setIsLoading(true);
		const { data: response } = await clientRequest(CreateNewIdeaDocument, data);
		if (response?.createIdea?.success) {
			router.push(`/idea/${response.createIdea.idea!.id}`);
		}
		setIsLoading(false);
	});

	let helpText = page.emptyHelpText;
	if (ideas && ideas.length > 0) {
		helpText = page.generalHelpText;
	}

	return (
		<>
			<div className="flex flex-col grid-cols-3 gap-10 lg:pb-14 lg:grid">
				<h1 className="text-32px lg:text-50px lg:col-span-2">{page.title}</h1>
				<MobileHorizontalRule />
				<ButtonPanel text="Submit new idea" onClick={() => setIsOpen(true)} className="col-start-3 !bg-charcoal" />
			</div>

			{helpText && <div className="mb-10 prose lg:mb-24" dangerouslySetInnerHTML={{ __html: helpText }} />}
			<div className="grid gap-[0.625rem] lg:gap-10 lg:grid-cols-2 xl:grid-cols-3">
				{ideas &&
					ideas
						.filter((i) => i !== null)
						.map((idea) => <IdeaCard key={idea!.id} idea={idea! as GQLBusinessIdeaType} />)}
			</div>

			<Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-40">
				<div className="fixed inset-0 bg-black/60" aria-hidden="true" />
				<div className="fixed inset-0 flex items-center justify-center p-4">
					<Dialog.Panel>
						<DarkCard className="w-[90vw] max-w-xl">
							<Dialog.Title className={"text-28px lg:text-32px mb-10"}>Create new Idea</Dialog.Title>
							<form onSubmit={handle} className="flex flex-col items-start gap-10">
								<Input label="Title" type="text" control={control} formKey="title" />
								<ButtonPanel text="Create" isLoading={isLoading} />
							</form>
						</DarkCard>
					</Dialog.Panel>
				</div>
			</Dialog>
		</>
	);
};

export default DashboardPage;
