import { Circle, Text } from "react-konva";
import { useEffect, useRef } from "react";
import { useMedia } from "react-use";

type DotWithLabelProps = {
	radius: number;
	centre: number;
	rotation: number;
	subject: string;
	petalRef: any;
	isHovered: boolean;
};

const DotWithLabel = ({ radius, centre, rotation, subject, petalRef, isHovered }: DotWithLabelProps) => {
	const labelColor = isHovered ? "#F4F1EB" : "#8D8C88";
	const dotRadius = 3; // adjust this value for the white dot size
	const labelDistance = 10; // adjust this value for the distance between the dot and label
	const isMobile = useMedia("(max-width: 1023px)", false);

	// Calculate the position for the white dot and label
	const dotX = centre + (radius - dotRadius / 2) * Math.cos((rotation - 90) * (Math.PI / 180));
	const dotY = centre + (radius - dotRadius / 2) * Math.sin((rotation - 90) * (Math.PI / 180));
	const labelX = centre + (radius + labelDistance) * Math.cos((rotation - 90) * (Math.PI / 180));
	const labelY = centre + (radius + labelDistance) * Math.sin((rotation - 90) * (Math.PI / 180));

	useEffect(() => {
		if (isHovered && petalRef.current) {
			petalRef.current.setAttrs({ opacity: 1 });
			petalRef.current.getLayer().batchDraw();
		} else if (!isHovered && petalRef.current) {
			petalRef.current.setAttrs({ opacity: 0.3 });
			petalRef.current.getLayer().batchDraw();
		}
	}, [isHovered, petalRef]);

	const textRef = useRef<any>(null);

	// Determine the quadrant of the dot
	let quadrant = Math.floor(((rotation - 90 + 360) % 360) / 90);

	// Special case 0 and 180 degrees
	if (rotation === 0) {
		quadrant = -1;
	} else if (rotation === 180) {
		quadrant = 4;
	}

	useEffect(() => {
		let offsetX = 0;
		let offsetY = 6;
		if (textRef.current) {
			const textWidth = textRef.current.getClientRect().width;
			const textHeight = textRef.current.getClientRect().height;
			let width = textWidth;

			if (isMobile) {
				width = textWidth > 80 ? 80 : textWidth;
			}

			switch (quadrant) {
				case -1: // 0 degrees
					offsetX = width / 2;
					offsetY = textHeight;
					break;
				case 0:
					offsetX = 0;
					offsetY = textHeight / 2;
					break;
				case 1:
					offsetX = width * (isMobile ? 0.85 : 0.95);
					offsetY = textHeight;
					break;
				case 2:
					offsetX = width * (isMobile ? 0.7 : 0.95);
					offsetY = textHeight / 2;
					break;
				case 3:
					offsetX = 3;
					offsetY = textHeight / 2;
					break;
				case 4: // 180 degrees
					offsetX = width / 2;
					offsetY = 0;
					break;
			}

			textRef.current.setAttrs({
				offsetX: offsetX,
				offsetY: offsetY,
				width: width,
			});

			textRef.current.getLayer().batchDraw();
		}
	}, [isMobile, quadrant, textRef]);

	return (
		<>
			<Circle x={dotX} y={dotY} radius={dotRadius} fill="white" />
			<Text
				ref={textRef}
				x={labelX}
				y={labelY}
				text={subject.toUpperCase()}
				fontSize={isMobile ? 10 : 12}
				fill={labelColor}
			/>
		</>
	);
};

export default DotWithLabel;
