import Tick from "@/svgs/tick.svg";
import clsx from "clsx";

type Props = {
	answeredCount: number;
	questionCount: number;
	includeTick?: boolean;
};

const CountOfQuestionsAnswered = ({ answeredCount, questionCount, includeTick = false }: Props) => {
	const finished = answeredCount === questionCount;
	return (
		<div
			className={clsx(
				"text-12px lg:text-14px italic",
				includeTick ? "flex justify-between items-center" : "mb-4 -mt-2"
			)}>
			{answeredCount} out of {questionCount} questions answered
			{includeTick && (
				<div
					className={clsx(
						"grid w-4 h-4 rounded-full border-1 place-items-center",
						finished ? "border-light" : "border-charcoal-light"
					)}>
					{finished && <Tick className="stroke-beige" />}
				</div>
			)}
		</div>
	);
};

export default CountOfQuestionsAnswered;
