import Tick from "@/svgs/tick.svg";

const ProgressBar = ({ percentage }: { percentage: number }) => {
	const finished = percentage === 100;
	return (
		<div className="flex items-center">
			<div className="w-full rounded-l-full bg-charcoal-light">
				<div
					className="h-0.5 rounded-l-full bg-purple"
					style={{
						width: `${percentage}%`,
					}}
				/>
			</div>
			<div
				className={`grid w-8 border-2 rounded-full aspect-square place-items-center ${
					finished ? "border-purple" : "border-charcoal-light"
				}`}>
				{finished && <Tick className="w-full stroke-beige" />}
			</div>
		</div>
	);
};

export default ProgressBar;
