"use client";
import { Line } from "react-konva";
import { useState } from "react";
import { useSpring, animated, config } from "@react-spring/web";
import { useKonvaInView } from "utils/hooks";
import ScoreDots from "./ScoreDots";

type PetalProps = {
	radius: number;
	centre: number;
	rotation: number;
	lengthPercent: number;
	colour: string;
	delay?: number;
	stageRef: any;
	showLabels: boolean;
	// eslint-disable-next-line no-unused-vars
	onHover: (val?: number) => void;
};

const AnimatedLine = animated(Line);

const Petal = ({ radius, centre, rotation, lengthPercent, colour, stageRef, showLabels, onHover }: PetalProps) => {
	if (lengthPercent > 1) {
		lengthPercent = 1;
	}
	const lengthPercentRescaled = lengthPercent === 0 ? 0 : 0.2 + lengthPercent * 0.8; // lengthPercent needs to be rescaled so the minimum starts at 20% and scales up to 100%
	const inView = useKonvaInView(stageRef);
	const [isPetalHovered, setIsPetalHovered] = useState(false);

	const length = radius * lengthPercentRescaled;

	const bulbPercent = 0.3; // widest point will be 30% of the length
	const bulbLocation = length * bulbPercent;

	const bulbWidth = radius * 0.12;

	const springProps = useSpring({
		from: {
			rotation: 0,
			x: centre, // Keep x at centre
			y: centre, // Keep y at centre
			opacity: showLabels ? 0.3 : 1,
			fill: colour,
			points: [0, 0, 0, 0, 0, 0, 0, 0], // Set the initial points to the centre
		},
		to: async (next) => {
			if (inView) {
				await next({
					rotation: rotation,
					x: centre,
					y: centre,
					fill: colour,
					points: [0, 0, -bulbWidth, -bulbLocation, 0, -length, bulbWidth, -bulbLocation],
				});
			}
		},
		config: config.slow,
	});
	return (
		<>
			<AnimatedLine
				{...springProps}
				tension={0.5}
				closed
				onMouseEnter={(e: any) => {
					e.target.setAttrs({ opacity: 1 });
					setIsPetalHovered(true);
					onHover();
				}}
				onMouseLeave={(e: any) => {
					e.target.setAttrs({ opacity: showLabels ? 0.3 : 1 });
					setIsPetalHovered(false);
					onHover(-1);
				}}
			/>
			{isPetalHovered && showLabels && (
				<ScoreDots centre={centre} lengthPercent={lengthPercent} radius={radius} rotation={rotation} />
			)}
		</>
	);
};

export default Petal;
