import { GQLRichTextBlock, GQLImageWithCaptionBlock, GQLCharBlock } from "graphql/generated-types";
import ImageWithCaptionBlock from "./ImageWithCaptionBlock";
import RichTextBlock from "./RichTextBlock";
import CharBlock from "./CharBlock";

export type BlockTypes = GQLRichTextBlock | GQLImageWithCaptionBlock | GQLCharBlock;

type Props = {
	block: BlockTypes;
};

const BlockRouter = (props: Props) => {
	switch (props.block?.blockType) {
		case "RichTextBlock":
			return <RichTextBlock block={props.block as GQLRichTextBlock} />;
		case "ImageWithCaptionBlock":
			return <ImageWithCaptionBlock block={props.block as GQLImageWithCaptionBlock} />;
		case "CharBlock":
			return <CharBlock block={props.block as GQLCharBlock} />;
		default:
			throw Error(`Unknown Block Type - ${props.block?.__typename}`);
	}
};

export default BlockRouter;
