import React from "react";
import { MobileHorizontalRule } from "./HorizontalRule";

const PageHeading = ({ children }: { children: React.ReactNode }) => {
	return (
		<>
			<div className="flex justify-between pb-9 lg:pb-14 lg:items-center to-lg:flex-col">
				<h1 className="text-32px lg:text-50px">{children}</h1>
			</div>
			<MobileHorizontalRule />
		</>
	);
};

export default PageHeading;
