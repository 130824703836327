import(/* webpackMode: "eager" */ "/vercel/path0/frontend/components/OverflowedText.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/components/petalGraph/PetalGraph.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/components/ProgressChart.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/components/QuizBody.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/components/QuizLink.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/components/svgs/arrow-circle.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/components/svgs/chevron-right.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/components/svgs/exclaim.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/components/svgs/tick.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/containers/pageTypes/ContentPage.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/containers/pageTypes/DashboardPage.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/containers/pageTypes/HomePage.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js")