"use client";
import ChevronRight from "@/svgs/chevron-right.svg";
import clsx from "clsx";
import PageHeading from "components/PageHeading";
import BlockRouter, { BlockTypes } from "components/blocks/BlockRouter";
import DarkCard from "containers/DarkCard";
import { AnimatePresence, motion } from "framer-motion";
import { GQLContentPage, GQLContentSection } from "graphql/generated-types";
import { usePathname } from "next/navigation";
import { useState } from "react";
import { useMedia } from "react-use";
import { slugify } from "utils/helpers";

const ContentPage = ({ data }: { data: GQLContentPage }) => {
	const pathName = usePathname();

	return (
		<>
			<PageHeading>{data.title}</PageHeading>
			<div className="grid h-max lg:grid-cols-4 gap-28">
				<aside className="sticky flex flex-col gap-3 top-10 h-min to-lg:hidden">
					{data?.sidebar?.map((block, i) => (
						<div key={i} className="flex flex-col gap-3">
							<a
								href={`#${block?.slug}`}
								className={clsx(
									"font-bold hover:translate-x-2 duration-300",
									pathName.includes(block?.slug!) && "underline underline-offset-4"
								)}
							>
								{block?.title}
							</a>
							{block?.children?.map((child, i) => (
								<a
									key={i}
									href={`#${child?.slug}`}
									className={clsx(
										"ml-4 hover:translate-x-2 duration-300",
										pathName.includes(child?.slug!) && "underline underline-offset-4"
									)}
								>
									{child?.title}
								</a>
							))}
						</div>
					))}
				</aside>
				<div className="col-span-3">
					<section className="flex flex-col gap-4 lg:gap-24">
						{data?.sections?.map((section, i) => (
							<Section key={i} section={section!} />
						))}
					</section>
				</div>
			</div>
		</>
	);
};

export default ContentPage;

const Section = ({ section }: { section: GQLContentSection }) => {
	const [isOpen, setIsOpen] = useState(false);
	const isMobile = useMedia("(max-width: 1023px)", false);

	const commonClasses = "flex justify-between w-full items-center";

	const innerSection = (
		<>
			<h2 id={slugify(section?.title!)} className="antialiased font-bold text-20px lg:text-28px">
				{section?.title}
			</h2>
			<ChevronRight
				className={clsx("w-3 mr-2 duration-300 stroke-slate-50 lg:hidden", isOpen ? "-rotate-90" : "rotate-90")}
			/>
		</>
	);

	return (
		<DarkCard key={section?.id} className="flex flex-col items-start gap-3 to-lg:py-4 to-lg:rounded-3xl">
			{isMobile ? (
				<button className={commonClasses} onClick={() => setIsOpen(!isOpen)}>
					{innerSection}
				</button>
			) : (
				<div className={commonClasses}>{innerSection}</div>
			)}
			<AnimatePresence>
				{(!isMobile || isOpen) && (
					<motion.div
						initial={{ height: 0, opacity: 0 }}
						animate={{ height: "auto", opacity: 1 }}
						exit={{ height: 0, opacity: 0 }}
						transition={{ duration: 0.3 }}
					>
						{section?.content?.map((block, i) => (
							<BlockRouter key={i} block={block as BlockTypes} />
						))}
					</motion.div>
				)}
			</AnimatePresence>
		</DarkCard>
	);
};
