import { GQLCharBlock } from "graphql/generated-types";
import { slugify } from "utils/helpers";

const CharBlock = ({ block }: { block: GQLCharBlock }) => {
	return (
		<h3 id={slugify(block.value)} className="mt-6 text-20px first:mt-0 lg:text-28px">
			{block.value}
		</h3>
	);
};

export default CharBlock;
