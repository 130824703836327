"use client";

import React from "react";
import { GQLQuizProgress } from "graphql/generated-types";
import CountOfQuestionsAnswered from "./CountOfQuestionsAnswered";
import ButtonPanel from "./ButtonPanel";
import { useParams } from "next/navigation";

const QuizLink: React.FC<GQLQuizProgress> = ({ title, slug, completed, started, answeredCount, questionCount }) => {
	const { ideaId } = useParams<{ ideaId: string }>();

	return (
		<div className="flex flex-col gap-1.5 leading-none lg:gap-2.5">
			<h4 className="font-bold text-18px lg:text-20px">{title}</h4>
			<CountOfQuestionsAnswered answeredCount={answeredCount!} questionCount={questionCount!} includeTick />
			<ButtonPanel
				href={`/idea/${ideaId}/quiz/${slug}`}
				text={`${completed ? "Revisit" : started ? "Continue" : "Start"} quiz`}
				className="to-lg:h-[1.875rem] to-lg:text-12px to-lg:pr-0 lg:mt-1.5"
				arrowClasses="to-lg:w-6"
			/>
		</div>
	);
};

export default QuizLink;
