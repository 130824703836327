import { Circle, Group } from "react-konva";

type ScoreDotsProps = {
	lengthPercent: number;
	radius: number;
	centre: number;
	rotation: number;
};

const ScoreDots = ({ lengthPercent, radius, rotation, centre }: ScoreDotsProps) => {
	const dotPositions = [0.2, 0.4, 0.6, 0.8, 1];

	return (
		<Group x={centre} y={centre} rotation={rotation}>
			{dotPositions.map((position, i) => {
				if (position === 1 && lengthPercent < 1) return null; // don't render the last dot because it's already there
				const xPos = 0;
				const yPos = -radius * position;
				const isBigger = Math.abs(lengthPercent - position) < 0.01;
				const dotRadius = isBigger ? 6 : 3;

				return <Circle key={i} x={xPos} y={yPos} radius={dotRadius} fill="white" />;
			})}
		</Group>
	);
};

export default ScoreDots;
