import { Listbox, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { FieldValues, Path, Control, useController, PathValue } from "react-hook-form";
import ChevronRight from "@/svgs/chevron-right.svg";
import { GQLSelectOption } from "graphql/generated-types";

type SelectProps<T extends FieldValues> = {
	label: string;
	formKey: Path<T>;
	options: GQLSelectOption[];
	control: Control<T>;
};

const Select = <T extends FieldValues>({ label, formKey, options, control }: SelectProps<T>) => {
	const {
		field: { ref, value, onChange },
		fieldState: { error },
	} = useController({
		name: formKey,
		control,
		defaultValue: "" as PathValue<T, Path<T>>,
		rules: {
			required: { value: true, message: "Please select an option" },
		},
	});

	return (
		<div className="relative w-full pb-1 whitespace-nowrap to-lg:text-14px">
			<label className="block mb-1 whitespace-pre-wrap">{label}</label>
			<Listbox value={value} onChange={onChange}>
				<div className="relative">
					<Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-transparent rounded-lg shadow-md cursor-default border-1 border-grey focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-14px">
						<span className="truncate">{options.find((option) => option.value === value)?.label || "Select"}</span>
						<span className="absolute inset-y-0 flex items-center pr-2 pointer-events-none right-2">
							<ChevronRight className="w-2 rotate-90 stroke-purple" aria-hidden="true" />
						</span>
					</Listbox.Button>
					<Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
						<Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base rounded-lg shadow-lg bg-charcoal max-h-60 ring-1 ring-grey focus:outline-none sm:text-14px">
							{options.map((option, i) => (
								<Listbox.Option
									key={i}
									className={({ active }) =>
										`relative cursor-default select-none py-2 pl-3 pr-4 ${active ? "bg-purple" : ""}`
									}
									value={option.value}
								>
									{({ selected }) => (
										<>
											<span ref={ref} className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>
												{option.label}
											</span>
										</>
									)}
								</Listbox.Option>
							))}
						</Listbox.Options>
					</Transition>
				</div>
			</Listbox>
			{error && <p className="whitespace-pre-wrap text-purple">{error.message as string}</p>}
		</div>
	);
};

export default Select;
