"use client";

import {
	GQLHomePage,
	GQLCreateNewUserMutationVariables,
	CreateNewUserDocument,
	GQLErrorType,
	GetIndustryOptionsDocument,
	GetIndustrySubcategoryOptionsDocument,
	GQLSelectOption,
} from "graphql/generated-types";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DarkCard from "containers/DarkCard";
import Image from "next/image";
import ButtonPanel from "components/ButtonPanel";
import ChevronLink from "components/ChevronLink";
import Exclaim from "@/svgs/exclaim.svg";
import { removeRootPage } from "utils/helpers";
import Input from "components/formFields/Input";
import Select from "components/formFields/Select";
import { fetchGraphQL } from "utils/request";
import { useSession } from "utils/hooks";
import { useRouter } from "next/navigation";

type Props = {
	dashboardSlug: string;
} & GQLHomePage;

const HomePageBody: React.FC<Props> = (pageProps) => {
	const { handleSubmit, setError, control, watch } = useForm<GQLCreateNewUserMutationVariables>();
	const { login } = useSession();
	const router = useRouter();
	const [isLoading, setIsLoading] = useState(false);
	const [industryOptions, setIndustryOptions] = useState<GQLSelectOption[]>([]);
	const [industrySubcategoryOptions, setIndustrySubcategoryOptions] = useState<GQLSelectOption[]>([]);

	const industry = watch("input.industry");

	useEffect(() => {
		async function getIndustryOptions() {
			const { data: industryData } = await fetchGraphQL(GetIndustryOptionsDocument);
			setIndustryOptions(industryData!.industryOptions as GQLSelectOption[]);
		}
		getIndustryOptions();
	}, []);

	useEffect(() => {
		async function getIndustrySubcategoryOptions(industry: string | undefined | null) {
			if (!industry) {
				return;
			}
			const { data: industrySubcatData } = await fetchGraphQL(GetIndustrySubcategoryOptionsDocument, {
				industry: industry,
			});
			setIndustrySubcategoryOptions(industrySubcatData!.industrySubcategoryOptions as GQLSelectOption[]);
		}
		getIndustrySubcategoryOptions(industry);
	}, [industry]);

	const handle = handleSubmit(async (data) => {
		setIsLoading(true);
		const { data: response } = await fetchGraphQL(CreateNewUserDocument, data);
		const errors = response?.createUser?.errors || [];
		if (errors.length > 0) {
			errors.forEach((error: GQLErrorType) => {
				const fieldKey = `input.${error.field}`;
				error.messages.forEach((message: string) => {
					setError(fieldKey as keyof GQLCreateNewUserMutationVariables, {
						message: message,
					});
				});
			});
		} else {
			await login(data.input.email, data.input.password1);
			window.location.href = `/${pageProps.dashboardSlug}`;
		}
		setIsLoading(false);
	});

	return (
		<section className="container pt-16 lg:pt-32 text-beige">
			<h1 className="mb-20 leading-none text-32px lg:w-4/5 lg:text-100px text-charcoal">{pageProps.introText}</h1>
			<div className="grid gap-[15px] lg:gap-10 lg:grid-cols-3">
				<div className="lg:col-span-2 min-h-[600px] lg:min-h-[37.5rem] relative">
					<Image
						src={pageProps.heroImage?.rendition?.url!}
						alt={pageProps.heroImage?.title! || ""}
						fill
						className="object-cover rounded-3xl"
					/>
				</div>
				<DarkCard className="flex flex-col justify-between antialiased lg:text-20px to-lg:gap-20">
					{pageProps.explainerText}
					<ButtonPanel text="Register below" href="#get-started" scroll={false} pointDown />
				</DarkCard>

				<DarkCard className="col-span-full">
					<h2 id="get-started" className="mb-5 text-28px lg:text-50px">
						Get Started, Register
					</h2>
					<div className="flex flex-col gap-20 lg:gap-10 xl:flex-row">
						<div className="w-full px-5 py-6 dashed-border lg:px-10 lg:py-12 h-min rounded-2xl">
							<div className="flex items-center gap-2 mb-4 text-20px">
								<Exclaim className="w-8" />
								Please note
							</div>
							<p>{pageProps.privacyText}</p>
							<ChevronLink href={removeRootPage(pageProps.privacyPage?.urlPath)} text="Learn more" className="mt-4" />
						</div>
						<form onSubmit={handle} className="flex flex-col items-start w-full gap-9 lg:gap-12">
							<Input label="First Name" type="text" formKey="input.firstName" control={control} />
							<Input label="Last Name" type="text" formKey="input.lastName" control={control} />
							<Input label="Email" type="text" formKey="input.email" control={control} />
							<Input label="Password" type="password" formKey="input.password1" control={control} />
							<Input label="Confirm password" type="password" formKey="input.password2" control={control} />
							{industryOptions && (
								<Select
									label="Which industry does your business operate in?"
									formKey="input.industry"
									options={industryOptions}
									control={control}
								/>
							)}
							{industrySubcategoryOptions.length > 0 && (
								<Select
									label="Which subcategory does your business operate in?"
									formKey="input.industrySubcategory"
									options={industrySubcategoryOptions}
									control={control}
								/>
							)}
							<ButtonPanel className="max-w-[420px]" text={"Register"} isLoading={isLoading} />
						</form>
					</div>
				</DarkCard>
			</div>
		</section>
	);
};

export default HomePageBody;
