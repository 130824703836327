import { useEffect, useState } from "react";
import { SessionData } from "./session";
import { useAsync } from "react-use";

export const useKonvaInView = (stageRef: any): boolean => {
	const [inView, setInView] = useState(false);

	useEffect(() => {
		if (!stageRef.current) return;

		const nativeNode = stageRef.current.getStage().container();

		const observer = new IntersectionObserver(
			([entry]) => {
				setInView(entry.isIntersecting);
			},
			{ threshold: 0.8 }
		);

		observer.observe(nativeNode);

		return () => {
			observer.disconnect();
		};
	}, [stageRef]);

	return inView;
};

export const useSession = () => {
	const sessionEndpoint = "/api/session";
	const { loading, value: session } = useAsync(async () => {
		const response = await fetch(sessionEndpoint);
		const result = await response.json();
		return result as SessionData;
	}, []);

	const login = async (username: string, password: string) => {
		return await fetch(sessionEndpoint, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				username,
				password,
			}),
		}).then((res) => {
			return res.json();
		});
	};

	const logout = async () => {
		await fetch(sessionEndpoint, {
			method: "DELETE",
		});
		window.location.href = "/login"; // refetches the header
	};

	return { loading, session, login, logout };
};
