"use client";
import { GQLQuizScore } from "graphql/generated-types";
import { Stage, Layer, Circle } from "react-konva";
import chroma from "chroma-js";
import { useEffect, useRef, useState, createRef } from "react";
import Petal from "./Petal";
import DotWithLabel from "./DotWithLabel";

const PetalGraph = ({ data, showLabels = false }: { data: any; showLabels?: boolean }) => {
	const graphContainerRef = useRef<HTMLDivElement>(null);
	const [width, setWidth] = useState(0);
	const setGraphWidth = () => {
		if (graphContainerRef.current) {
			setWidth(graphContainerRef.current.offsetWidth);
		}
	};
	const stageRef = useRef(null);
	const [hoveredPetalIndex, setHoveredPetalIndex] = useState(-1);

	useEffect(() => {
		setGraphWidth();
		window.addEventListener("resize", setGraphWidth);
		return () => {
			window.removeEventListener("resize", setGraphWidth);
		};
	}, [graphContainerRef]);

	const labelOffset = showLabels ? 30 : 0; // 30 accounts for the width of the label and white dot
	const withLabelsRadius = 0.8 * (width / 2) - labelOffset;

	const radius = showLabels ? withLabelsRadius : width / 2;

	const strokeWidth = 1;

	const colourScale = chroma.scale(["#5E709B", "#D872C4", "#4A4678"]).mode("lch").colors(data.length);

	// add alpha to colour scale
	colourScale.forEach((colour, i) => {
		colourScale[i] = chroma(colour).alpha(0.5).css();
	});

	const refsArray = useRef(
		Array(data.length)
			.fill(null)
			.map(() => createRef())
	);

	return (
		<div ref={graphContainerRef} className="aspect-square">
			<Stage ref={stageRef} width={width} height={width}>
				<Layer>
					<Circle
						x={width / 2}
						y={width / 2}
						radius={radius > 1 ? radius - strokeWidth : 0}
						stroke={"#3B3B3A"}
						strokeWidth={strokeWidth}
					/>
				</Layer>
				<Layer>
					{data.map((quizScore: GQLQuizScore, i: number) => (
						<Petal
							key={i}
							radius={radius - strokeWidth * 2}
							centre={width / 2}
							rotation={(360 / data.length) * i}
							lengthPercent={quizScore.score! / 5}
							colour={colourScale[i]}
							stageRef={stageRef}
							showLabels={showLabels}
							onHover={(val = i) => setHoveredPetalIndex(val)}
						/>
					))}
				</Layer>
				{showLabels && (
					<Layer>
						{data.map((quizScore: GQLQuizScore, i: number) => (
							<DotWithLabel
								key={i}
								radius={radius}
								centre={width / 2}
								rotation={(360 / data.length) * i}
								subject={quizScore.subject!}
								petalRef={refsArray.current[i]}
								isHovered={hoveredPetalIndex === i}
							/>
						))}
					</Layer>
				)}
			</Stage>
		</div>
	);
};

export default PetalGraph;
