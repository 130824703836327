import React from "react";

export const MobileHorizontalRule = () => {
	return (
		<div className="lg:hidden">
			<hr className="-mx-5 bg-hr mb-[-1px]" />
			<hr className="mb-6 border-t border-charcoal" />
		</div>
	);
};

export const DesktopHorizontalRule = () => {
	return (
		<>
			<hr className="w-screen bg-hr -z-10 to-lg:hidden" />
			<div className="container to-lg:hidden">
				<hr className="border-t border-charcoal mt-[-1px]" />
			</div>
		</>
	);
};
