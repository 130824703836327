export const slugify = (text: string) => {
	return text
		.toString() // Convert to string
		.toLowerCase() // Convert to lowercase
		.trim() // Remove leading/trailing white-space
		.replace(/\s+/g, "-") // Replace spaces with -
		.replace(/[^\w\-]+/g, "") // Remove all non-word chars
		.replace(/\-\-+/g, "-") // Replace multiple - with single -
		.replace(/^-+/, "") // Trim - from start of text
		.replace(/-+$/, ""); // Trim - from end of text
};

export const arrayToOptions = (arr: string[]): { label: string; value: string }[] => {
	return arr.map((value) => ({
		label: value,
		value: value,
	}));
};

export function convertToEmbedUrl(videoUrl: string) {
	const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?([\w\-]{10,12})(?:[&\?].*?)?/;
	const match = videoUrl.match(regex);
	const videoId = match ? match[1] : null;

	if (videoId) {
		return `https://www.youtube.com/embed/${videoId}?controls=0&autoplay=1&modestbranding=1&rel=0&showinfo=0&iv_load_policy=3`;
	}

	return null;
}

export const removeRootPage = (path?: string) => {
	if (!path) return "";
	return path.replace(/^\/home+/, "");
};
