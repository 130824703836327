import Chevron from "@/svgs/chevron-right.svg";
import clsx from "clsx";
import Link from "next/link";

const ChevronLink = ({ href, text, className }: { href?: string; text: string; className?: string }) => {
	const classes = clsx("flex items-center gap-2 group w-max", className);

	const inner = (
		<>
			<Chevron className="h-2.5 group-hover:translate-x-1 duration-300 stroke-purple" />
			{text}
		</>
	);

	if (href) {
		return (
			<Link href={href} className={classes}>
				{inner}
			</Link>
		);
	}

	return <div className={classes}>{inner}</div>;
};

export default ChevronLink;
