import { GQLCharBlock, GQLImageChooserBlock, GQLImageWithCaptionBlock } from "graphql/generated-types";
import Image from "next/image";
import React from "react";

const ImageWithCaptionBlock = ({ block }: { block: GQLImageWithCaptionBlock }) => {
	const imageBlock = block.blocks.find((block) => block.field === "image") as GQLImageChooserBlock;
	const caption = block.blocks.find((block) => block.field === "caption") as GQLCharBlock;
	const { image } = imageBlock;

	return (
		<div className="w-full my-6">
			<Image
				className="w-full"
				src={image?.rendition?.url!}
				alt={image?.rendition?.image.title!}
				width={615}
				height={600}
			/>
			{caption && <p>{caption.rawValue}</p>}
		</div>
	);
};

export default ImageWithCaptionBlock;
