import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { Control, FieldValues, Path, PathValue, useController } from "react-hook-form";

type InputProps<T extends FieldValues> = {
	label: string;
	type: string;
	formKey: Path<T>;
	control: Control<T>;
};

const Input = <T extends FieldValues>({ label, type, formKey, control }: InputProps<T>) => {
	const {
		field: { ref, onChange, onBlur, value },
		fieldState: { error },
	} = useController({
		name: formKey,
		control,
		defaultValue: "" as PathValue<T, Path<T>>,
	});
	const [multiLineLabel, setMultiLineLabel] = useState(false);
	const labelRef = useRef<HTMLSpanElement>(null);

	useEffect(() => {
		if (labelRef.current?.offsetHeight && labelRef.current?.offsetHeight > 30) {
			setMultiLineLabel(true);
		}
	}, [labelRef.current?.offsetHeight]);

	return (
		<div className={clsx("relative w-full pb-1 whitespace-nowrap to-lg:text-14px", multiLineLabel && "mt-4")}>
			<label className="relative whitespace-pre-wrap group">
				<span
					ref={labelRef}
					className={clsx(
						"duration-150 absolute bottom-0",
						value
							? clsx("text-12px lg:text-14px", multiLineLabel ? "-translate-y-1/2" : "-translate-y-full")
							: clsx(
									"group-focus-within:text-12px lg:group-focus-within:text-14px",
									multiLineLabel ? "group-focus-within:-translate-y-1/2" : "group-focus-within:-translate-y-full"
							  )
					)}>
					{label}
				</span>
				<input
					className="w-full text-base bg-transparent outline-none lg:text-20px"
					type={type}
					onChange={onChange}
					onBlur={onBlur}
					value={value}
					ref={ref}
					required
				/>
			</label>
			<hr className="border-t border-grey" />
			{error && <p className="absolute text-purple">{error.message}</p>}
		</div>
	);
};

export default Input;
